














import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import { IExplorerSelectedFolder } from '@/store/types/IExplorerSelectedFolder';

@Component
export default class ExplorerRecordHeader extends Vue {
  @Prop() record!: IRecord;
  @Prop() ishovered!: boolean;
  @Prop() readingmodeon!: boolean;
  @Prop() selectedfolder!: IExplorerSelectedFolder;
  // get selectedfolder() {
  //   return ExplorerUiStateModule.selectedFolder;
  // }

  get showHeader() {
    return this.selectedfolder.FolderName !== 'books';
    //return !this.readingmodeon || this.ishovered;
  }
}
