






















































import { Component, Vue, Prop } from 'vue-property-decorator';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { IRecord } from '@/store/types/IRecord';
import explorerrecordcontent from '@/pages/explorer/components/ExplorerRecordContent.vue';
import explorerrecordheader from '@/pages/explorer/components/ExplorerRecordHeader.vue';
import explorerrecordfooter from '@/pages/explorer/components/ExplorerRecordFooter.vue';
import explorerrecordnotes from '@/pages/explorer/components/ExplorerRecordNotes.vue';

@Component({
  components: {
    explorerrecordcontent,
    explorerrecordheader,
    explorerrecordfooter,
    explorerrecordnotes,
  },
})
export default class ExplorerRecord extends Vue {
  @Prop() record!: IRecord;
  @Prop() searchText!: string;
  isHovered = false;
  showRecordDeletionAnimation = false;
  showCopiedOverlay = false;

  // Animation rules
  // When reading mode is off

  // * everything is visible
  // * the record separator is not visible
  // * nothing gets animated

  // When readmode is on

  // * The header is not visible
  // * notes are visible
  // * The footer is not visible

  copied() {
    console.log('copied');
    this.showCopiedOverlay = true;
  }

  get showFully() {
    return false;
    //return this.isHovered || !this.explorersettings!.ReadingModeOn;
  }

  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }

  get explorersettings() {
    return ExplorerDataModule.ExplorerSettings;
  }

  get displayHighlightColor() {
    return ExplorerDataModule.ExplorerSettings?.DisplayHighlightColor;
  }

  get readingModeOn() {
    return ExplorerDataModule.ExplorerSettings?.ReadingModeOn;
  }

  get displayRecordSeparator() {
    return ExplorerDataModule.ExplorerSettings
      ?.DisplayRecordSeparator;
  }

  toggleEditing(record: IRecord, value: boolean) {
    const newrecord = Object.assign({}, record, {
      Editing: value,
    });
    ExplorerDataModule.updateRecordSuccess(newrecord);
  }

  handleHover(hovered: boolean) {
    this.isHovered = hovered;
  }

  deleteRecord(record: IRecord) {
    this.showRecordDeletionAnimation = true;
    // this.recordDeleted.emit(record);
  }
}
