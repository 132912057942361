


































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import explorerrecordintegrations from '@/pages/explorer/components/ExplorerRecordIntegrations.vue';
import explorerrecordlocation from '@/pages/explorer/components/ExplorerRecordLocation.vue';
import explorerrecordtypeicon from '@/pages/explorer/components/ExplorerRecordTypeIcon.vue';
import explorerrecordcreated from '@/pages/explorer/components/ExplorerRecordCreated.vue';
import explorerrecordsource from '@/pages/explorer/components/ExplorerRecordSource.vue';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import explorerrecordreadonamazonapp from '@/pages/explorer/components/ExplorerRecordReadOnAmazonApp.vue';
@Component({
  components: {
    explorerrecordintegrations,
    explorerrecordlocation,
    explorerrecordtypeicon,
    explorerrecordcreated,
    explorerrecordsource,
    explorerrecordreadonamazonapp,
    // explorerrecordtags,
  },
})
export default class ExplorerRecordInfo extends Vue {
  @Prop() record!: IRecord;
  @Prop() displayhighlightcolour!: boolean;

  toggleFavorite() {
    const newrecord = Object.assign({}, this.record, {
      Favorite: !this.record.Favorite,
    });
    ExplorerDataModule.toggleFavorite(newrecord);
  }

  restoreRecord() {
    ExplorerDataModule.restoreRecord(this.record);
  }

  get kindleHighlightColor() {
    return [
      {
        highlightcolornone: this.record.HighlightColorID == 5,
      },
      {
        highlightcoloryellow: this.record.HighlightColorID == 1,
      },
      {
        highlightcolorpink: this.record.HighlightColorID == 2,
      },
      {
        highlightcolorblue: this.record.HighlightColorID == 3,
      },
      {
        highlightcolororange: this.record.HighlightColorID == 4,
      },
    ];
  }
}
