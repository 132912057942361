

















import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';

@Component
export default class ExplorerRecordViewTags extends Vue {
  @Prop() record!: IRecord;

  findTagId(tagName: string) {
    return ExplorerDataModule.findTagId(tagName);
  }
}
