











































































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import LayoutModule from '@/store/modules/LayoutModule';

@Component
export default class ExplorerRecordActionsVertical extends Vue {
  @Prop() record!: IRecord;
  menuHovered = false;

  onOver() {
    this.menuHovered = true;
  }
  onLeave() {
    this.menuHovered = false;
  }

  get isDesktop() {
    return !LayoutModule.isMobile;
  }

  @Emit()
  showtags() {
    // Comment to stop ts lint error
  }

  @Emit()
  copied() {
    // Comment to stop ts lint error
  }

  @Emit()
  editing() {
    // Comment to stop ts lint error
  }

  deleteRecord() {
    ExplorerDataModule.deleteRecord(this.record);
  }

  ToggleFavorite() {
    const newrecord = Object.assign({}, this.record, {
      Favorite: !this.record.Favorite,
    });
    ExplorerDataModule.toggleFavorite(newrecord);
  }

  AddAttachedNote() {
    ExplorerDataModule.addAttachedNote(this.record);
  }
}
