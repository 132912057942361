












































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
//import explorerrecordseparator from '@/pages/explorer/components/ExplorerRecordSeparator.vue';
import explorerrecordactions from '@/pages/explorer/components/ExplorerRecordActions.vue';
import explorerrecordactionsvertical from '@/pages/explorer/components/ExplorerRecordActionsVertical.vue';
import explorerrecordinfo from '@/pages/explorer/components/ExplorerRecordInfo.vue';
import explorerrecordviewtags from '@/pages/explorer/components/ExplorerRecordViewTags.vue';
import explorerrecordedittags from '@/pages/explorer/components/ExplorerRecordEditTags.vue';

@Component({
  components: {
    explorerrecordactions,
    explorerrecordactionsvertical,
    //  explorerrecordseparator,
    explorerrecordinfo,
    explorerrecordviewtags,
    explorerrecordedittags,
  },
})
export default class ExplorerRecordFooter extends Vue {
  @Prop() record!: IRecord;
  @Prop() displayhighlightcolour!: boolean;
  @Prop() displayrecordseparator!: boolean;
  @Prop() readingmodeon!: boolean;
  showDeleteConfirm = false;
  copytoclipboardsuccess = false;
  editTagsVisible = false;
  isHovered = false;

  showEditTags() {
    this.editTagsVisible = true;
  }

  @Emit()
  editing() {
    // Comment to stop ts lint error
  }

  @Emit()
  copied() {
    // Comment to stop ts lint error
  }

  // todo should do a nice animation or something
  copiedtoclipboard() {
    this.copytoclipboardsuccess = true;
    // alert('copied to clipboard');
  }

  DeleteRecord() {
    this.showDeleteConfirm = false;
    ExplorerDataModule.deleteRecord(this.record);
  }

  RestoreRecord() {
    ExplorerDataModule.restoreRecord(this.record);
  }

  ShowDeleteConfirm() {
    this.showDeleteConfirm = true;
  }
  CancelDelete() {
    this.showDeleteConfirm = false;
  }

  handleHover(hovered: boolean) {
    this.isHovered = hovered;
  }

  ToggleFavorite() {
    const newrecord = Object.assign({}, this.record, {
      Favorite: !this.record.Favorite,
    });
    ExplorerDataModule.toggleFavorite(newrecord);
  }

  AddAttachedNote() {
    ExplorerDataModule.addAttachedNote(this.record);
  }

  get showFooterMenu() {
    return !this.readingmodeon || this.isHovered;
  }
}
