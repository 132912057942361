
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import LayoutModule from '@/store/modules/LayoutModule';

@Component
export default class ExplorerRecordReadOnAmazonApp extends Vue {
  @Prop() record!: IRecord;
  get isDesktop() {
    return !LayoutModule.isMobile;
  }
}
