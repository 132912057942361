




















































































import {
  Component,
  Vue,
  Prop,
  Emit,
  PropSync,
} from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';

@Component
export default class ExplorerRecordContent extends Vue {
  @Prop() parentrecord!: IRecord; // only used in note mode
  @Prop() record!: IRecord;
  @Prop() editing!: boolean;
  @Prop() notemode!: boolean;
  @Prop() searchText!: string;
  @PropSync('showcopiedoverlay') showCopiedOverlay!: boolean;
  content = '';

  created() {
    this.content = this.record.Content;
  }

  hideOverlay() {
    setTimeout(() => (this.showCopiedOverlay = false), 2000);
  }

  get isEditing() {
    return this.editing; // || this.editinglocal;
  }

  @Emit()
  editingcancelled() {
    if (this.notemode && !this.content) {
      const newrecord = Object.assign({}, this.record, {
        Content: 'new note',
      });
      ExplorerDataModule.updateRecord(newrecord);
    } else {
      return this.record;
    }
  }

  @Emit()
  editingstarted() {
    return this.record;
  }

  Save() {
    const newrecord = Object.assign({}, this.record, {
      Content: this.content,
    });
    ExplorerDataModule.updateRecord(newrecord);
  }

  DeleteAttachedNote() {
    ExplorerDataModule.deleteAttachedNote(this.record);
  }

  UnlinkAttachedNote() {
    // this.unlinkAttachedNote.emit(this.record);
  }
}
