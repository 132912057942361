var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.imports.length == 0)?_c('b-row',{attrs:{"no-gutters":"","align-v":"center"}},[_c('b-col'),_vm._v(" "),_c('b-col',{attrs:{"cols":"8"}},[_c('h3',{staticClass:"text-center"},[_c('i18n',{attrs:{"path":"TL_EXPLORER_NO_RECORDS_PLEASE_IMPORT"},scopedSlots:_vm._u([{key:"import",fn:function(){return [_c('a',{attrs:{"to":"/import"}},[_vm._v(_vm._s(_vm.$t('TL_EXPLORER_NO_RECORDS_GO_TO_IMPORT_PAGE')))])]},proxy:true}],null,false,2287538113)})],1)]),_vm._v(" "),_c('b-col',{attrs:{"cols":"2"}})],1):_vm._e(),_vm._v(" "),(_vm.imports.length > 0 && _vm.records.length == 0)?_c('b-row',{attrs:{"no-gutters":"","align-v":"center"}},[_c('b-col',{attrs:{"cols":"2"}}),_vm._v(" "),_c('b-col',{attrs:{"cols":"8"}},[(_vm.selectedfolder.FolderName === 'all')?_c('h3',{staticClass:"text-center"},[_c('i18n',{attrs:{"path":"TL_EXPLORER_NO_RECORDS_PLEASE_IMPORT"},scopedSlots:_vm._u([{key:"import",fn:function(){return [_c('a',{attrs:{"to":"'/import'"}},[_vm._v(_vm._s(_vm.$t('TL_EXPLORER_NO_RECORDS_GO_TO_IMPORT_PAGE')))])]},proxy:true}],null,false,1745124609)})],1):_vm._e(),_vm._v(" "),(_vm.selectedfolder.FolderName === 'tags')?_c('h3',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPLORER_NO_RECORDS_FOR_THIS_TAG'))+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.selectedfolder.FolderName === 'books' &&
          _vm.selectedfolder.SelectedItem.Deleted === false
        )?_c('h3',{staticClass:"text-center"},[_c('i18n',{attrs:{"path":"TL_EXPLORER_NO_RECORDS_YOU_HAVE_DELETED_ALL"},scopedSlots:_vm._u([{key:"deleted",fn:function(){return [_c('a',{attrs:{"to":"'/explorer/deleted'"}},[_vm._v(_vm._s(_vm.$t('TL_EXPLORER_NO_RECORDS_SHOW_DELETED')))])]},proxy:true}],null,false,3380753510)})],1):_vm._e(),_vm._v(" "),(
          _vm.selectedfolder.FolderName === 'books' &&
          _vm.selectedfolder.SelectedItem.Deleted === true
        )?_c('h3',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPLORER_NO_RECORDS_THIS_BOOK_HAS_BEEN_DELETED'))+"\n        "),_c('i',{staticClass:"fa fa-undo",attrs:{"aria-hidden":"true"}}),_vm._v(".\n      ")]):_vm._e(),_vm._v(" "),(_vm.selectedfolder.FolderName === 'deleted')?_c('h3',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPLORER_NO_RECORDS_NO_DELETED_RECORDS'))+"\n        "),_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),(_vm.selectedfolder.FolderName === 'favorites')?_c('h3',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPLORER_NO_RECORDS_NO_FAVORITES'))+"\n        "),_c('i',{staticClass:"fa fa-heart",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),(_vm.selectedfolder.FolderName === 'search')?_c('h3',{staticClass:"text-center"},[_c('i',{staticClass:"fa fa-search",attrs:{"aria-hidden":"true"}}),_vm._v("\n        "+_vm._s(_vm.$t('TL_EXPLORER_NO_RECORDS_MATCH', {
            searchTerm: _vm.selectedfolder.SelectedId,
          }))+"\n      ")]):_vm._e()]),_vm._v(" "),_c('b-col',{attrs:{"cols":"2"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }