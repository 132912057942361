






import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import i18n from '@/i18n';

@Component
export default class ExplorerRecordLocation extends Vue {
  @Prop() record!: IRecord;

  get locationorpage() {
    if (this.record.CreatedInWebsite) {
      return 'Loc n/a';
    } else {
      return this.record.Page
        ? this.record.Page
        : this.record.Location;
    }
  }

  get tooltip() {
    return this.record.Page
      ? i18n.t('TL_SHARED_PAGE')
      : i18n.t('TL_SHARED_LOCATION');
  }
}
