














































































































import { Component, Vue } from 'vue-property-decorator';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';

@Component
export default class ExplorerNoRecords extends Vue {
  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }

  get imports() {
    return ExplorerDataModule.Imports;
  }
  get records() {
    return ExplorerDataModule.Records;
  }
}
