





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';

@Component
export default class ExplorerRecordIntegrations extends Vue {
  @Prop() record!: IRecord;
}
