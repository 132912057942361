























































































































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import explorerrecordintegrations from '@/pages/explorer/components/ExplorerRecordIntegrations.vue';
import explorerrecordlocation from '@/pages/explorer/components/ExplorerRecordLocation.vue';
import explorerrecordtypeicon from '@/pages/explorer/components/ExplorerRecordTypeIcon.vue';
import explorerrecordcreated from '@/pages/explorer/components/ExplorerRecordCreated.vue';
import explorerrecordsource from '@/pages/explorer/components/ExplorerRecordSource.vue';

@Component({
  components: {
    explorerrecordintegrations,
    explorerrecordlocation,
    explorerrecordtypeicon,
    explorerrecordcreated,
    explorerrecordsource,
  },
})
export default class ExplorerRecordActions extends Vue {
  @Prop() record!: IRecord;
  showDeleteConfirm = false;
  copytoclipboardsuccess = false;

  @Emit()
  toggletags() {
    // Comment to stop ts lint error
  }

  @Emit()
  editing() {
    // Comment to stop ts lint error
  }

  // todo should do a nice animation or something
  copiedtoclipboard() {
    this.copytoclipboardsuccess = true;
    // alert('copied to clipboard');
  }

  DeleteRecord() {
    this.showDeleteConfirm = false;
    ExplorerDataModule.deleteRecord(this.record);
  }

  RestoreRecord() {
    ExplorerDataModule.restoreRecord(this.record);
  }

  ShowDeleteConfirm() {
    this.showDeleteConfirm = true;
  }
  CancelDelete() {
    this.showDeleteConfirm = false;
  }

  ToggleFavorite() {
    const newrecord = Object.assign({}, this.record, {
      Favorite: !this.record.Favorite,
    });
    ExplorerDataModule.toggleFavorite(newrecord);
  }

  AddAttachedNote() {
    ExplorerDataModule.addAttachedNote(this.record);
  }
}
