





























import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import {
  Component,
  Vue,
  Prop,
  PropSync,
} from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';

@Component
export default class ExplorerRecordEditTags extends Vue {
  @Prop() record!: IRecord;
  @PropSync('visible') isVisible!: string;
  @PropSync('recordtags') tags!: string;

  get tagsOptions() {
    return ExplorerDataModule.tagsTitleArray.filter(
      (t) => !this.tags.includes(t),
    );
  }

  tagsInput(tags: any) {
    var newTags = this.newTags(this.record!.TagsArray, tags);
    newTags.forEach((t) => {
      ExplorerDataModule.addTag({
        RecordId: this.record.RecordId,
        TagTitle: t,
      });
    });

    var deletedTags = this.deletedTags(this.record!.TagsArray, tags);

    deletedTags.forEach((t) => {
      ExplorerDataModule.removeTag({
        RecordId: this.record.RecordId,
        TagTitle: t,
      });
    });
  }

  newTags(existingstate: string[], newstate: string[]): string[] {
    let difference = newstate.filter(
      (x) => !existingstate.includes(x),
    );
    return difference;
  }

  deletedTags(existingstate: string[], newstate: string[]): string[] {
    let difference = existingstate.filter(
      (x) => !newstate.includes(x),
    );
    return difference;
  }
}
