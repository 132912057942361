








import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';

@Component
export default class ExplorerRecordTypeIcon extends Vue {
  @Prop() record!: IRecord;
  getIcon(annotationid: number) {
    switch (annotationid) {
      case 1:
        // Clipping
        return 'book';
      case 2:
        // Note
        return 'edit';
      case 3:
        // Bookmark
        return 'bookmark';
      case 4:
        // Highlight
        return 'highlighter';
    }
  }
}
