var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recordmenu"},[(!_vm.record.Deleted)?_c('b-button-group',{staticClass:"bg-white",attrs:{"size":"sm"}},[_c('b-button',{attrs:{"variant":"default"},on:{"click":function($event){return _vm.ToggleFavorite()}}},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          _vm.$t('TL_EXPLORER_RECORD_FOOTER_FAVORITE')
        ),expression:"\n          $t('TL_EXPLORER_RECORD_FOOTER_FAVORITE')\n        ",modifiers:{"hover":true,"top":true}}],class:{ favorite: _vm.record.Favorite },attrs:{"icon":"heart","size":"lg","fixed-width":""}})],1),_vm._v(" "),_c('b-button',{attrs:{"variant":"default"},on:{"click":function($event){return _vm.editing()}}},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('TL_SHARED_EDIT')),expression:"$t('TL_SHARED_EDIT')",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"pencil-alt","size":"lg","fixed-width":""}})],1),_vm._v(" "),_c('b-button',{attrs:{"variant":"default"},on:{"click":function($event){return _vm.AddAttachedNote()}}},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          _vm.$t('TL_EXPLORER_RECORD_FOOTER_ADD_NOTE')
        ),expression:"\n          $t('TL_EXPLORER_RECORD_FOOTER_ADD_NOTE')\n        ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"edit","size":"lg","fixed-width":""}})],1),_vm._v(" "),(!_vm.showDeleteConfirm)?_c('b-button',{attrs:{"variant":"default"},on:{"click":function($event){return _vm.ShowDeleteConfirm()}}},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('TL_SHARED_DELETE')),expression:"$t('TL_SHARED_DELETE')",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"trash","size":"lg","fixed-width":""}})],1):_vm._e(),_vm._v(" "),(_vm.showDeleteConfirm)?_c('b-button',{staticClass:"font-weight-bold",attrs:{"variant":"default","size":"sm"}},[_vm._v(_vm._s(_vm.$t('TL_EXPLORER_RECORD_FOOTER_CONFIRM')))]):_vm._e(),_vm._v(" "),(_vm.showDeleteConfirm)?_c('b-button',{staticClass:"font-weight-bold",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.DeleteRecord()}}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_DELETE')))]):_vm._e(),_vm._v(" "),(_vm.showDeleteConfirm)?_c('b-button',{attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.CancelDelete()}}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_CANCEL')))]):_vm._e(),_vm._v(" "),_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.record.Content),expression:"record.Content"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copiedtoclipboard),expression:"copiedtoclipboard",arg:"success"}],attrs:{"id":"copytoclipboard","variant":"default"}},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          _vm.$t('TL_EXPLORER_RECORD_COPY_TO_CLIPBOARD')
        ),expression:"\n          $t('TL_EXPLORER_RECORD_COPY_TO_CLIPBOARD')\n        ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"copy","size":"lg","fixed-width":""}})],1),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
        _vm.$t('TL_EXPLORER_RECORD_FOOTER_ADD_TAGS_BELOW')
      ),expression:"\n        $t('TL_EXPLORER_RECORD_FOOTER_ADD_TAGS_BELOW')\n      ",modifiers:{"hover":true,"top":true}}],attrs:{"variant":"default"},on:{"click":_vm.toggletags}},[_c('font-awesome-icon',{attrs:{"icon":"tags","size":"lg","fixed-width":""}})],1)],1):_vm._e(),_vm._v(" "),(_vm.record.Deleted)?_c('b-button-group',{attrs:{"size":"sm","role":"group","aria-label":""}},[_c('b-button',{attrs:{"variant":"default"},on:{"click":function($event){return _vm.RestoreRecord()}}},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          _vm.$t('TL_EXPLORER_RECORD_FOOTER_RESTORE')
        ),expression:"\n          $t('TL_EXPLORER_RECORD_FOOTER_RESTORE')\n        ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"undo","size":"lg","fixed-width":""}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }