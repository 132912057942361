








import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRecord } from '@/store/types/IRecord';
import moment from 'moment';

@Component
export default class ExplorerRecordCreated extends Vue {
  @Prop() record!: IRecord;

  get createdKindle() {
    // if(this.record){
    return moment(this.record.CreatedKindle).format('lll');
    // } else {
    //   return '';
    // }
  }
}
