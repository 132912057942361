






















import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import explorerrecordcontent from '@/pages/explorer/components/ExplorerRecordContent.vue';
import { IRecord } from '@/store/types/IRecord';

@Component({
  components: {
    explorerrecordcontent,
  },
})
export default class ExplorerRecordNotes extends Vue {
  @Prop() parentrecord!: IRecord;
  @Prop() notes!: IRecord[];
  @Prop() searchText!: string;

  toggleEditing(record: IRecord, value: boolean) {
    const newrecord = Object.assign({}, record, {
      Editing: value,
    });
    ExplorerDataModule.updateRecordSuccess(newrecord);
  }
}
