








































import { Component, Vue } from 'vue-property-decorator';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import Logoloadingindicator from '@/components/logoloadingindicator.vue';
import explorerrecord from '@/pages/explorer/components/ExplorerRecord.vue';
import explorernorecords from '@/pages/explorer/components/ExplorerNoRecords.vue';

@Component({
  components: {
    Logoloadingindicator,
    explorerrecord,
    explorernorecords,
  },
})
export default class Explorerrecords extends Vue {
  loadMoreRecords() {
    ExplorerDataModule.loadMoreRecords();
  }

  get searchText() {
    return ExplorerUiStateModule.searchText;
  }

  get isLoading() {
    return ExplorerDataModule.isLoading;
  }

  get isLoadingMore() {
    return ExplorerDataModule.isLoadingMore;
  }

  get pagingComplete() {
    return ExplorerDataModule.PagingComplete;
  }
  get records() {
    return ExplorerDataModule.Records;
  }
}
